import $ from 'jquery';
import jqueryValidation from 'jquery-validation';

export class Kupon_forms {

    init () {

        /*lézeres szemmutet kedvezmeny*/
        $("#zfemto7d").on('click', function (e) {

            e.preventDefault();

            let button = $(this);
            if (!button.hasClass('disabled')) {

                let form = $("#zfemto7d-form");
                form.validate({
                    groups: {
                        nameGroup: "name email phone_number aszf privacy_policy"
                    },
                    rules: {
                        name: {
                            required: true
                        },
                        email: {
                            required: true,
                            email: true
                        },
                        phone_number: {
                            required: true,
                            number: true
                        },
                        aszf: {
                            required: true
                        },
                        privacy_policy: {
                            required: true
                        }

                    },
                    messages: {
                        name: {
                            required: 'A mező kitöltése kötelező!'
                        },
                        phone_number: {
                            required: 'A mező kitöltése kötelező!',
                            number: "A mező csak számokat tartalmazhat!"
                        },
                        email: {
                            email: 'Nem megfelelő email cím!',
                            required: 'A mező kitöltése kötelező!'
                        },
                        message: {
                            required: 'A mező kitöltése kötelező!'
                        },
                        aszf: {
                            required: 'Általános Szerződési Feltételei kitöltése kötelező!'
                        },
                        privacy_policy: {
                            required: 'Adatvédelmi Tájékoztató kitöltése kötelező!'
                        }
                    }
                });


                let isValid = form.valid();
                let errorMsg = $(".error-msg");
                if (isValid) {

                    button.addClass('disabled');
                    errorMsg.hide();

                    let newsletterString;
                    let isNewsletterChecked = $(".coupon-newsletter-container input[type=checkbox]").is(':checked');
                    if (isNewsletterChecked)
                        newsletterString = 'newsletter';
                    else
                        newsletterString = 'nonewsletter';


                    let data = {
                        name: form.find("#name_zfemto7d").val(),
                        email: form.find("#email_zfemto7d").val(),
                        phone_number: form.find("#phone_number_zfemto7d").val(),
                        newsletter: newsletterString
                    };

                    $.ajax({
                        url: ajaxurl,
                        dataType: 'json',
                        type: 'POST',
                        data: {
                            event: 'Laser_Discount',
                            data: data
                        },
                        beforeSend: function () {
                            $("#loading-image").show();
                            button.addClass("disabel-btn");
                            button.prop('disabled', true);
                        },
                        success: function (res) {
                            $("#loading-image").hide();
                            button.removeClass('disabled');
                            $('html, body').animate({
                                scrollTop: $(".kupon-box").offset().top + (-40)
                            }, 1500);
                            form.slideUp(700);
                            $('.kupon-box').hide();
                            $(".thank-you-text").removeClass("d-none");


                        },
                        error: function (res) {
                            button.removeClass('disabled');
                            alert('Hiba történt, kérem próbálja újból!');
                        }
                    });

                } else {
                    console.log("form nem jó");
                    $(".form-conatainer").addClass('position100');
                }
            }
        });

        /*Zero oldal*/
        $("#zero").on('click', function (e) {

            e.preventDefault();

            let button = $(this);
            if (!button.hasClass('disabled')) {

                let form = $("#zero-form");
                form.validate({
                    groups: {
                        nameGroup: "name email phone_number aszf privacy_policy"
                    },
                    rules: {
                        name: {
                            required: true
                        },
                        email: {
                            required: true,
                            email: true
                        },
                        phone_number: {
                            required: true,
                            number: true
                        },
                        aszf: {
                            required: true
                        },
                        privacy_policy: {
                            required: true
                        }

                    },
                    messages: {
                        name: {
                            required: 'A mező kitöltése kötelező!'
                        },
                        phone_number: {
                            required: 'A mező kitöltése kötelező!',
                            number: "A mező csak számokat tartalmazhat!"
                        },
                        email: {
                            email: 'Nem megfelelő email cím!',
                            required: 'A mező kitöltése kötelező!'
                        },
                        message: {
                            required: 'A mező kitöltése kötelező!'
                        },
                        aszf: {
                            required: 'Általános Szerződési Feltételei kitöltése kötelező!'
                        },
                        privacy_policy: {
                            required: 'Adatvédelmi Tájékoztató kitöltése kötelező!'
                        }
                    }
                });


                let isValid = form.valid();
                let errorMsg = $(".error-msg");
                if (isValid) {

                    button.addClass('disabled');
                    errorMsg.hide();

                    let newsletterString;
                    let isNewsletterChecked = $(".coupon-newsletter-container input[type=checkbox]").is(':checked');
                    if (isNewsletterChecked)
                        newsletterString = 'newsletter';
                    else
                        newsletterString = 'nonewsletter';


                    let data = {
                        name: form.find("#name_zero").val(),
                        email: form.find("#email_zero").val(),
                        phone_number: form.find("#phone_number_zero").val(),
                        newsletter: newsletterString
                    };

                    $.ajax({
                        url: ajaxurl,
                        dataType: 'json',
                        type: 'POST',
                        data: {
                            event: 'zero-form',
                            data: data
                        },
                        beforeSend: function () {
                            $("#loading-image").show();
                            button.addClass("disabel-btn");
                            button.prop('disabled', true);
                        },
                        success: function (res) {
                            $("#loading-image").hide();
                            button.removeClass('disabled');
                            $('html, body').animate({
                                scrollTop: $(".kupon-box").offset().top + (-40)
                            }, 1500);
                            form.slideUp(700);
                            $('.kupon-box').hide();
                            $(".thank-you-text").removeClass("d-none");


                        },
                        error: function (res) {
                            button.removeClass('disabled');
                            alert('Hiba történt, kérem próbálja újból!');
                        }
                    });

                } else {
                    console.log("form nem jó");
                    $(".form-conatainer").addClass('position100');
                }
            }
        });

        /*Zero oldal*/
        $("#prk").on('click', function (e) {

            e.preventDefault();

            let button = $(this);
            if (!button.hasClass('disabled')) {

                let form = $("#prk-form");
                form.validate({
                    groups: {
                        nameGroup: "name email phone_number aszf privacy_policy"
                    },
                    rules: {
                        name: {
                            required: true
                        },
                        email: {
                            required: true,
                            email: true
                        },
                        phone_number: {
                            required: true,
                            number: true
                        },
                        aszf: {
                            required: true
                        },
                        privacy_policy: {
                            required: true
                        }

                    },
                    messages: {
                        name: {
                            required: 'A mező kitöltése kötelező!'
                        },
                        phone_number: {
                            required: 'A mező kitöltése kötelező!',
                            number: "A mező csak számokat tartalmazhat!"
                        },
                        email: {
                            email: 'Nem megfelelő email cím!',
                            required: 'A mező kitöltése kötelező!'
                        },
                        message: {
                            required: 'A mező kitöltése kötelező!'
                        },
                        aszf: {
                            required: 'Általános Szerződési Feltételei kitöltése kötelező!'
                        },
                        privacy_policy: {
                            required: 'Adatvédelmi Tájékoztató kitöltése kötelező!'
                        }
                    }
                });


                let isValid = form.valid();
                let errorMsg = $(".error-msg");
                if (isValid) {

                    button.addClass('disabled');
                    errorMsg.hide();

                    let newsletterString;
                    let isNewsletterChecked = $(".coupon-newsletter-container input[type=checkbox]").is(':checked');
                    if (isNewsletterChecked)
                        newsletterString = 'newsletter';
                    else
                        newsletterString = 'nonewsletter';


                    let data = {
                        name: form.find("#name_prk").val(),
                        email: form.find("#email_prk").val(),
                        phone_number: form.find("#phone_number_prk").val(),
                        newsletter: newsletterString
                    };

                    $.ajax({
                        url: ajaxurl,
                        dataType: 'json',
                        type: 'POST',
                        data: {
                            event: 'prk-form',
                            data: data
                        },
                        beforeSend: function () {
                            $("#loading-image").show();
                            button.addClass("disabel-btn");
                            button.prop('disabled', true);
                        },
                        success: function (res) {
                            $("#loading-image").hide();
                            button.removeClass('disabled');
                            $('html, body').animate({
                                scrollTop: $(".kupon-box").offset().top + (-40)
                            }, 1500);
                            form.slideUp(700);
                            $('.kupon-box').hide();
                            $(".thank-you-text").removeClass("d-none");


                        },
                        error: function (res) {
                            button.removeClass('disabled');
                            alert('Hiba történt, kérem próbálja újból!');
                        }
                    });

                } else {
                    console.log("form nem jó");
                    $(".form-conatainer").addClass('position100');
                }
            }
        });

        /*lézeres szemmutet kedvezmeny*/
        $("#diploma").on('click', function (e) {

            e.preventDefault();

            let button = $(this);
            if (!button.hasClass('disabled')) {

                let form = $("#diploma-form");
                form.validate({
                    groups: {
                        nameGroup: "name email phone_number aszf privacy_policy"
                    },
                    rules: {
                        name: {
                            required: true
                        },
                        email: {
                            required: true,
                            email: true
                        },
                        phone_number: {
                            required: true,
                            number: true
                        },
                        aszf: {
                            required: true
                        },
                        privacy_policy: {
                            required: true
                        }

                    },
                    messages: {
                        name: {
                            required: 'A mező kitöltése kötelező!'
                        },
                        phone_number: {
                            required: 'A mező kitöltése kötelező!',
                            number: "A mező csak számokat tartalmazhat!"
                        },
                        email: {
                            email: 'Nem megfelelő email cím!',
                            required: 'A mező kitöltése kötelező!'
                        },
                        message: {
                            required: 'A mező kitöltése kötelező!'
                        },
                        aszf: {
                            required: 'Általános Szerződési Feltételei kitöltése kötelező!'
                        },
                        privacy_policy: {
                            required: 'Adatvédelmi Tájékoztató kitöltése kötelező!'
                        }
                    }
                });


                let isValid = form.valid();
                let errorMsg = $(".error-msg");
                if (isValid) {

                    button.addClass('disabled');
                    errorMsg.hide();

                    let newsletterString;
                    let isNewsletterChecked = $(".coupon-newsletter-container input[type=checkbox]").is(':checked');
                    if (isNewsletterChecked)
                        newsletterString = 'newsletter';
                    else
                        newsletterString = 'nonewsletter';


                    let data = {
                        name: form.find("#name_diploma").val(),
                        email: form.find("#email_diploma").val(),
                        phone_number: form.find("#phone_number_diploma").val(),
                        newsletter: newsletterString
                    };

                    $.ajax({
                        url: ajaxurl,
                        dataType: 'json',
                        type: 'POST',
                        data: {
                            event: 'diploma-form',
                            data: data
                        },
                        beforeSend: function () {
                            $("#loading-image").show();
                            button.addClass("disabel-btn");
                            button.prop('disabled', true);
                        },
                        success: function (res) {
                            $("#loading-image").hide();
                            button.removeClass('disabled');
                            $('html, body').animate({
                                scrollTop: $(".kupon-box").offset().top + (-40)
                            }, 1500);
                            form.slideUp(700);
                            $('.form-header').hide();
                            $(".thank-you-text").removeClass("d-none");


                        },
                        error: function (res) {
                            button.removeClass('disabled');
                            alert('Hiba történt, kérem próbálja újból!');
                        }
                    });

                } else {
                    console.log("form nem jó");
                    $(".form-conatainer").addClass('position100');
                }
            }
        });

        /*lézeres szemmutet kedvezmeny*/
        $("#clear").on('click', function (e) {

            e.preventDefault();

            let button = $(this);
            if (!button.hasClass('disabled')) {

                let form = $("#clear-form");
                form.validate({
                    groups: {
                        nameGroup: "name email phone_number aszf privacy_policy"
                    },
                    rules: {
                        name: {
                            required: true
                        },
                        email: {
                            required: true,
                            email: true
                        },
                        phone_number: {
                            required: true,
                            number: true
                        },
                        aszf: {
                            required: true
                        },
                        privacy_policy: {
                            required: true
                        }

                    },
                    messages: {
                        name: {
                            required: 'A mező kitöltése kötelező!'
                        },
                        phone_number: {
                            required: 'A mező kitöltése kötelező!',
                            number: "A mező csak számokat tartalmazhat!"
                        },
                        email: {
                            email: 'Nem megfelelő email cím!',
                            required: 'A mező kitöltése kötelező!'
                        },
                        message: {
                            required: 'A mező kitöltése kötelező!'
                        },
                        aszf: {
                            required: 'Általános Szerződési Feltételei kitöltése kötelező!'
                        },
                        privacy_policy: {
                            required: 'Adatvédelmi Tájékoztató kitöltése kötelező!'
                        }
                    }
                });


                let isValid = form.valid();
                let errorMsg = $(".error-msg");
                if (isValid) {

                    button.addClass('disabled');
                    errorMsg.hide();

                    let newsletterString;
                    let isNewsletterChecked = $(".coupon-newsletter-container input[type=checkbox]").is(':checked');
                    if (isNewsletterChecked)
                        newsletterString = 'newsletter';
                    else
                        newsletterString = 'nonewsletter';


                    let data = {
                        name: form.find("#name_clear").val(),
                        email: form.find("#email_clear").val(),
                        phone_number: form.find("#phone_number_clear").val(),
                        newsletter: newsletterString
                    };

                    $.ajax({
                        url: ajaxurl,
                        dataType: 'json',
                        type: 'POST',
                        data: {
                            event: 'clear-form',
                            data: data
                        },
                        beforeSend: function () {
                            $("#loading-image").show();
                            button.addClass("disabel-btn");
                            button.prop('disabled', true);
                        },
                        success: function (res) {
                            $("#loading-image").hide();
                            button.removeClass('disabled');
                            $('html, body').animate({
                                scrollTop: $(".kupon-box").offset().top + (-40)
                            }, 1500);
                            form.slideUp(700);
                            $('.form-header').hide();
                            $(".thank-you-text").removeClass("d-none");


                        },
                        error: function (res) {
                            button.removeClass('disabled');
                            alert('Hiba történt, kérem próbálja újból!');
                        }
                    });

                } else {
                    console.log("form nem jó");
                    $(".form-conatainer").addClass('position100');
                }
            }
        });

    }
}
