import $ from 'jquery';
import jqueryValidation from 'jquery-validation';


export class Request_an_appointment {
    init(){
        $("#request-an-appointment").on('click', function (e) {
            e.preventDefault();


            let form = $('#request_an_appointment');



            form.validate({
                groups: {
                    nameGroupRequest_an_appointment: "name request_select email phone date age aszf privacy_policy"
                },
                rules: {
                    name: {
                        required: true
                    },
                    request_select: {
                        required: true
                    },
                    email: {
                        required: true,
                        email: true
                    },
                    phone: {
                        required: true,
                        number: true
                    },
                    date: {
                        required: true
                    },
                    age: {
                        required: true,
                        number: true
                    },
                    aszf: {
                        required: true
                    },
                    privacy_policy: {
                        required: true
                    }
                },
                messages: {
                    request_select: {
                        required: "Minden mező kitöltése kötelező!"
                    },
                    name: {
                        required: "Minden mező kitöltése kötelező!"
                    },
                    email: {
                        required: "Minden mező kitöltése kötelező!",
                        email: "Nem megfelelő az email formátuma!"
                    },
                    phone: {
                        required: "Minden mező kitöltése kötelező!",
                        number: "A mező csak számokat tartalmazhat!"
                    },
                    date: {
                        required: "Minden mező kitöltése kötelező!"
                    },
                    age: {
                        required: "Minden mező kitöltése kötelező!",
                        number: "A mező csak számokat tartalmazhat!"
                    },
                    aszf: {
                        required: 'Általános Szerződési Feltételei kitöltése kötelező!'
                    },
                    privacy_policy: {
                        required: 'Adatvédelmi Tájékoztató kitöltése kötelező!'
                    },
                    nameGroupRequest_an_appointment: {
                        required: "Minden mező kitöltése kötelező!"
                    }

                }

            });


            let isValid = form.valid();

            let selectedRadioButton = form.find('input[name="radiosurgery"]:checked');
            let selectedvoucher = form.find('input[name="checkboxvoucher"]:checked');

            console.log( selectedvoucher);

            if (isValid) {


                let data = {
                    phone: form.find("#phone").val(),
                    dd_text: form.find("#inputStateRequest_an_appointment option:selected").text(),
                    type: form.find("#inputStateRequest_an_appointment option:selected").val(),
                    email: form.find("#email").val(),
                    name: form.find("#name").val(),
                    date: form.find("#date").val(),
                    age: form.find("#age").val(),
                    note: form.find("#comment").val(),
                    surgery: selectedRadioButton.val(),
                    voucher: selectedvoucher.val()
                };


                $.ajax({
                    type: 'POST',
                    url: ajaxurl,
                    dataType: 'json',
                    data: {
                        action: 'RequestAnAppointment',
                        data: data

                    },
                    beforeSend: function () {
                        $("#panelsStayOpen-collapseRequest-an-appointment #loading-image").show();
                        $("#request-an-appointment").prop('disabled', true);
                    },
                    success: function (res) {

                        $("#panelsStayOpen-collapseRequest-an-appointment .box").fadeTo("slow", 0.00, function () { //fade
                            $(this).slideUp(1000, function () { //slide up
                                $(this).remove(); //then remove from the DOM
                                $("#panelsStayOpen-collapseRequest-an-appointment .thank-you-text").removeClass("d-none");
                            });
                        });


                        $('html, body').animate({
                            scrollTop: $("#request_an_appointment").offset().top + (-40)
                        }, 1500);

                    },
                    error: function (res) {

                        /* $(".valasz-oldal").removeClass("hidden-thank-you");*/
                    }

                });
            }
        });
    }
}

